@import "constants";

.app {
  .round {
    display: inline-block;
    margin: 45px 15px 65px 15px;
    text-align: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 900;
    &.smaller {
      font-size: 18px; } }

  .square {
    display: block;
    margin: 26px auto;
    text-align: center;
    width: 100%;
    height: 52px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 900; }
  .small_square {
    text-align: center;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 900;
    &:hover {
      opacity: 0.3; } }

  .orange {
    background-color: $orange;
    color: #fff; }

  .blue {
    background-color: $blue;
    color: #fff; }

  .red {
    background-color: $red;
    color: #fff; }

  .black {
    font-size: 16px;
    background-color: #000;
    color: #fff;
    &:disabled {
      background-color: $orange; } } }
