body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

 * {
   box-sizing: border-box;
 }

select,
textarea,
input {
  box-shadow: none;
  border: none;
  outline: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer
}
