@import "constants";

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0 35px 20px 35px;
  height: 100vh;

  .has_error {
    border: 2px solid red; }

  header {
    position: relative;
    width: 100%;
    height: 100px;
    font-size: 50px;
    img {
      cursor: pointer;
      margin-top: 19px;
      width: 135px;
      transform: rotate(0deg);
      transition: transform 0.2s; }

    .hamburger {
      z-index: 10;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -35px;
      background-color: #000;
      .hamburger-inner {
        background-color: #fff;
        &::before,
        &::after {
          background-color: #fff; } } } }

  footer {
    height: 60px;
    text-align: center;
    margin-top: 40px;

    .arrow {
      background-color: transparent;
      &:disabled {
        opacity: 0.3; } }

    .right {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }

    .dots {
      display: inline-block;
      width: 160px;
      vertical-align: middle;
      height: 52px;

      dot {
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-right: 9px;
        background-color: $gray;
        border: 1px solid $gray;
        &.active {
          background-color: #fff;
          border-color: $orange; }
        &.previous {
          background-color: $orange;
          border-color: $orange; }
        &.next {
          background-color: $gray;
          border-color: $gray; } } } }

  main {
    flex: auto;
    position: relative; }

  h1 {
    font-size: 20px;
    margin: 0; }

  h3 {
    color: $orange;
    font-size: 25px;
    font-weight: 900;
    line-height: 1.2;
    &::first-letter {
      text-transform: uppercase; } }
  h4 {
    font-size: 16px; }

  p, li {
    font-size: 16px;
    line-height: 1.4em;
    min-height: 1px; }

  select,
  textarea,
  input {
    font-family: 'Raleway', sans-serif;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: $gray;
    margin: 12px auto 30px;
    font-size: 16px;
    padding: 0 12px;
    &[type="checkbox"] {
      height: 25px;
      margin: 0 0 0 0px;
      padding: 0;
      width: 25px; } }

  .checkbox {
    display: flex;
    label {
      padding-left: 15px; }
    padding-bottom: 20px; }

  textarea {
    height: 65px;
    padding: 12px; }


  label {
    display: block;
    width: 100%;
    margin: 0 auto;
    font-weight: 900; }

  .faq {
    h4 {
      font-size: 20px; }
    margin-bottom: 50px; }

  .hint {
    font-size: 15px;
    margin: -25px 0 25px 0;
    font-style: italic; } }

.menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: $orange;

  ul {
    margin: 170px auto;
    list-style: none;
    li {
      padding: 16px 0;
      button {
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 23px;
        font-weight: bold; } } } }

.edit_screen {
  img {
    position: absolute;
    right: 0px;
    cursor: pointer;
    &:hover {
      opacity: 0.3; } }
  hr {
    margin: 30px 0;
    border-top: 1px solid silver;
    border-bottom: none; }
  h3 {
    margin-bottom: 40px; }
  h4 {
    color: $orange; } }

.last_screen {
  img {
    display: block;
    margin: 100px auto 60px auto; }
  h2 {
    color: #707070;
    width: 200px;
    text-align: center;
    margin: 20px auto; } }

.popup {
  position: absolute;
  top: 0px;
  left: -25px;
  padding: 15px 22px;
  width: 290px;
  height: 270px;
  border-radius: 5px;
  background-color: $orange;
  color: #fff;
  font-weight: 500;
  line-height: 1.4;
  z-index: 1000;
  &:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    margin: 20px 0 0 200px;
    border-right: 50px solid $orange;
    border-bottom: 52px solid transparent; }
  button {
    position: absolute;
    top: 9px;
    right: 9px;
    font-size: 30px;
    font-weight: 500;
    background-color: transparent;
    color: #fff; } }

.logos {
  padding-top: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  a {
    outline: none;
    width: 40%;
    margin: 0px 0px 70px;
    img {
      width: 100%; } }
  #eu_logo {
    width: 30%; }
  #uzuvrh_logo {
    width: 27%;
    margin-right: 7%; }
  #zaklada_logo {
    width: 31%;
    margin-right: 4%; } }
