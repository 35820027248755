@media only screen and (max-width: 375px) {
  .black {
    font-size: 12px !important; }
  .app {
    footer .dots {
      width: auto;
      dot {
        margin-right: 5px; } } }
  .menu ul li button {
    font-size: 18px; } }

@media only screen and (min-width: 392px) {
  .popup {
    top: -25px;
    left: -25px; }
  .logos {
    width: 100%;
    margin: auto; } }


@media only screen and (min-width: 435px) {
  .popup {
    top: -40px;
    left: -25px; }
  .logos {
    width: 80%; } }


@media only screen and (min-width: 769px) {
  .app {
    margin-top: 100px;
    width: 500px;
    height: 700px;
    main, select, textarea, input, label, .square {
      width: 430px; } }
  .menu {
    height: 730px; }
  .logos {
    width: 100%; } }

@media only screen and (min-width: 1000px) {
  .app {
    header img {
      &:hover {
        transform: rotate(-5deg) scale(1.2); } } } }
